import React, { useState, useEffect } from "react"
import { SliderImages } from '@reactiveonline/frontend_shared_components'
import ServiceDescription from "./ServiceDescription"
import CreateReservationForm from "../shared/CreateReservationForm"
import { getTranslatableField } from "../../helpers/utils"
import { loadServiceViewAnalytics } from "../../helpers/analytics"

export default function Service({
  appProps, service, currency, isOnSlider, servicePageLayout, hasBuilderContent, getBuilderContentUrl,
  getAvailableTimeSlotsPath, getAvailableEmployeesPath, stores, reservationPath
}) {
  const {
    currentLocale, translations, thumbnailsPosition,
  } = appProps

  let serviceImages = service.images.length > 0 ? service.images.map( image => {
    return {
      original: image.preview,
      thumbnail: image.thumbnail,
      fullscreen: image.banner,
    }
  }
  ) : [{
    original: 'https://storage.googleapis.com/reactive_files_eu/no-image.jpg',
    thumbnail: '',
    fullscreen: ''
  }]

  const serviceDescription = getTranslatableField(service, 'description', currentLocale, false)

  useEffect(() => {
    loadServiceViewAnalytics(service, appProps)
  }, []);

  return (
    <>
      <div id='product_page_above_product'></div>
      <div className={ `service-show-wrapper ${servicePageLayout != 'image_top' && 'main-container'} ${servicePageLayout}` }>
        <div className="service-image" style={{ position: 'relative' }}>
          { servicePageLayout === 'image_top' &&
            <>
              <div className='overlay' />
              <h1 className="flex-box items-center content-center overlay-title">
                { getTranslatableField(service, 'title', currentLocale, false) }
              </h1>
            </>
          }
          <SliderImages
            images={ serviceImages }
            showThumbnails={ false }
            showFullscreenButton={ false }
            showNav={ serviceImages && serviceImages.length > 1 && true }
            showBullets={ serviceImages && serviceImages.length > 1 && true }
            lightbox={ true }
            thumbnailPosition={ serviceImages && serviceImages.length > 1 ? thumbnailsPosition : 'bottom' }
          />
        </div>

        <div className={`service-details flex-box flex-wrap content-start ${servicePageLayout === 'image_top' && 'main-container'}`}>
          <div className="service-info">
            { servicePageLayout != 'image_top' &&
              <h1>
                { getTranslatableField(service, 'title', currentLocale, false) }
              </h1>
            }
            <div>
              <div className='display-price'>{appProps.translations.reservation_summary.cost}: {`${service.price} ${currency}`}</div>
              <div className='display-duration'>{appProps.translations.reservation_summary.duration}: {`${service.intervalTime} ${translations.services.minutes}`}</div>
            </div>
            { serviceDescription && serviceDescription != '<p></p>' &&
              <ServiceDescription
                description={ serviceDescription }
              />
            }
            <div id='product_page_below_title'></div>
          </div>

          <CreateReservationForm
            appProps={ appProps }
            selectedService={ service }
            actionText={ translations.reservations.add_to_cart }
          />

        </div>
      </div>
    </>
  )
}
