import React from "react"
import ReservationInformation from './ReservationInformation'
import { AuthorizableLink } from '@reactiveonline/frontend_shared_components'

export default function Complete({
  appProps, reservation, paymentMethodTitle, contactDetails, billingAddress, cancelReservationPath, bankDetails, createChatMessagePath, fetchChatMessagesPath
}) {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const isOverView = urlParams.get('isOverView')

  return (
    <>
      <div className="main-container">
        <div className="checkouts-wrapper order-complete">
          <>
            <h1>
              { !!isOverView ?
                `${ appProps.translations.reservations.reservation_number }: ${ reservation.number }` : appProps.translations.reservations.thank_you_for_your_reservation }
              </h1>

            { !!isOverView && reservation.status == 'cancelled' ?
              <h2 style={{ marginTop: 10, color: 'red' }}>
                { appProps.translations.statuses.cancelled }
              </h2>
            :
              (!isOverView &&
                <div style={{ fontSize: 15, marginTop: 10 }}>
                  { appProps.translations.reservations.confirmation_and_updates_email } { reservation.email }
                </div>
              )
            }
          </>

          { !appProps.user &&
            <div className="account">
              { appProps.userFormLoginType ?
                <AuthorizableLink
                  appProps={ appProps }
                  linkContent={ <div> { appProps.translations.reservations.no_account } <b>{ appProps.translations.reservations.register_now }</b> </div>}
                  linkRef={ appProps.accountPath }
                  authorizableScreen={ 'register' }
                /> :
                <a className="account_icon my-account" href={ appProps.accountPath }></a>
              }
            </div>
          }

          <ReservationInformation
            appProps={ appProps }
            reservation={ reservation }
            paymentMethodTitle={ paymentMethodTitle }
            contactDetails={ contactDetails }
            billingAddress={ billingAddress }
            bankDetails={ bankDetails }
            createChatMessagePath={ createChatMessagePath }
            fetchChatMessagesPath={ fetchChatMessagesPath }
          />
        </div>
      </div>
    </>
  )
}
